<template>
	<div id="pdf-box" class="document-list-review">
		<Alerts v-if="showAlert" :alertType="alertType" :alertMsg="alertMsg" />
		<v-container v-if="!docError && !loading">
			<div id="pdf-view" class="col-md-12 col-sm-12 pa-0">
				<auth-user-pdf-viewer :token="token" :authId="authId" :requestId="requestId" :fileName="fileName"
					@documentError="documentError($event)"></auth-user-pdf-viewer>
			</div>
		</v-container>
		<div class="footer-message pa-1">
			<div>
				<v-row>
					<v-container class="py-0 fill-height">
						<v-row class="py-4 px-1">
							<div class="col-md-6 col-6 links text-left">
								<a class="porto-button white--text text-decoration-underline"
									@click="showSelectedDocuments">{{ $t("document_review.sign_the_batch_documents") }}
								</a>
							</div>
							<div class="col-md-6 col-6 links text-right">
								<a class="porto-button white--text text-decoration-underline" @click="checkLater">{{
									$t("document_review.check_later") }}</a>
							</div>
						</v-row>
						<v-row class="d-flex justify-space-between">
							<div class="col-md-2 col-6 sign">
								<v-btn width="100%" large color="#47D764" block dark class="sign-add-to-batch-btn"
									:loading="loading" @click="signDocument()" :disabled="addToBatchDisable">
									{{ $t("document_review.add_to_signature_batch") }}
								</v-btn>
							</div>
							<div class="col-md-2 col-6 reject">
								<v-btn width="100%" large color="#FF000D" class="sign-reject-btn" block dark
									@click="reject()">
									<v-icon class="mr-2">mdi-close-circle-outline</v-icon>
									{{ $t("document_review.decline") }}
								</v-btn>
							</div>
						</v-row>
					</v-container>
				</v-row>
			</div>
			<v-container>
				<div class="document-count pa-2">
					{{ currentPdf + 1 }} / {{ docList.length }}
				</div>
			</v-container>
		</div>

		<reject-document-modal :dialog="rejectDocumentDialog" :errorMsg="errorMsg" :error="error" :requestId="requestId"
			:authId="authId" @rejectCertificateDocument="rejectCertificateDocument"
			id="rejectDocModal"></reject-document-modal>

		<DefaultLoading v-if="loading" :loading="loading" />
	</div>
</template>

<script>
import DefaultLoading from "./shared/DefaultLoading.component";
import AuthUserPdfViewer from "./shared/AuthUserPdfViewer.component";
import RejectDocumentModal from "./shared/RejectDocumentModal.component";
import Alerts from "../shared/Alerts.component";
import { SignaturesService } from "../../services/multiple/signatures-service";

export default {
	name: "document-list-review",
	components: {
		DefaultLoading,
		AuthUserPdfViewer,
		RejectDocumentModal,
		Alerts,
	},
	data: function () {
		return {
			token: "",
			authId: "",
			requestId: null,
			fileName: "",
			loading: false,
			docError: false,
			currentPdf: 0,
			rejectDocumentDialog: false,
			errorMsg: "",
			error: false,
			docList: [],
			selectedDocList: [],
			addToBatchDisable: false,
			showAlert: false,
			alertType: "",
			alertMsg: "",
			pendingStatus: 20,
			page: 1,
			totalPages: 0,
			authenticationCode:null,
			authToken:null
		};
	},
	computed: {
		hasAssociation() {
			return this.$store.getters['common/hasAssociation']
		}
	},
	methods: {
		showSelectedDocuments() {
			this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}multiple-pdf` })
		},
		checkLater() {
			this.currentPdf++;
			if (this.docList.length <= this.currentPdf) {
				this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}multiple-pdf` })
			} else {
				this.addToBatchDisable = false;
				this.loadPdf();
			}
		},
		documentError(value) {
			this.docError = value;
		},
		loadPdf() {
			let documentDetails = this.docList[this.currentPdf];
			this.token = documentDetails["apiToken"];
			this.authId = localStorage.getItem("auth_id") || null;
			this.requestId = documentDetails["id"];
			this.fileName = documentDetails["signatureTransaction"]["description"];
			const checkedList = this.checkReviewedDocumentList();
			if (checkedList && checkedList.find((o) => o.id === this.requestId)) {
				this.addToBatchDisable = true;
			}
		},
		reject() {
			this.rejectDocumentDialog = true;
		},
		rejectCertificateDocument() {
			this.showAlert = true;
			this.alertType = "success";
			this.alertMsg = this.$t("document_review.successfully_rejected");
			this.rejectDocumentDialog = false;
			this.hideAlerts(1000);
			setTimeout(
				function () {
					this.checkLater();
				}.bind(this),
				1000
			);
		},
		hideAlerts(time) {
			setTimeout(
				function () {
					this.showAlert = false;
					this.alertType = "";
					this.alertMsg = "";
				}.bind(this),
				time
			);
		},
		setParams(params) {
			params["page"] = this.page;
			params["status"] = this.pendingStatus;
			return params;
		},
		loadDocumentList(paramsList) {
			this.loading = true;
			let params = this.setParams(paramsList);
			const apiToken = localStorage.getItem("auth_user_token");
			const authCode = this.authenticationCode;


			SignaturesService.getCertificateSignatureRequests(apiToken, params,authCode)
				.then((response) => {
					this.docList = this.docList.concat(
						response.data.signatureRequestsList
					);

					this.page = response.data.pageNumber;
					this.totalPages = response.data.totalPages;
					this.loading = false;
					this.loadAllPagination();
				})
				.catch(() => {
					this.loading = false;
				});
		},
		loadAllPagination() {
			if (this.totalPages > this.page) {
				this.page = this.page + 1;
				this.loadDocumentList({});
			} else {
				this.loadPdf();
			}
		},

		checkReviewedDocumentList() {
			const reviewed =
				localStorage.getItem("reviewed_list") != ""
					? JSON.parse(localStorage.getItem("reviewed_list"))
					: "";
			return reviewed.length > 0 ? reviewed : "";
		},
		signDocument() {
			this.addToBatchDisable = true;
			this.selectedDocList.push(this.docList[this.currentPdf]);
			localStorage.setItem(
				"reviewed_list",
				JSON.stringify(this.selectedDocList)
			);

			this.showAlert = true;
			this.alertType = "success";
			this.alertMsg = this.$t(
				"document_review.successfully_added_to_the_batch"
			);
			this.hideAlerts(1000);
			setTimeout(
				function () {
					this.checkLater();
				}.bind(this),
				1000
			);
		},
	},
	created() {
		this.authenticationCode = localStorage.getItem('auth_id')|| null;
		localStorage.setItem("reviewed_list", "");
		this.loadDocumentList({});
	},
};
</script>
